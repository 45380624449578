'use strict';

var isMobileOrTablet = window.matchMedia('only screen and (max-width: 1024px)').matches;

const TRY_IN_STORE_CONTAINER = '[data-js="tryinstore-container"]';
const TRY_IN_STORE_RESULTS_SELECTOR = isMobileOrTablet ? '[data-js="store-locator-results-mobile"]' : '[data-js="store-locator-results"]';

const APPOINTMENT_CONTAINER = '[data-js="appointment-container"]';
const APPOINTMENT_RESULTS_CONTAINER_SELECTOR = isMobileOrTablet ? '[data-js="appointment-stores-results-mobile"]' : '[data-js="store-locator-results"]';

const STORE_RESULTS_CONTAINER_SELECTOR = '[data-js="store-locator-results"]';

module.exports = function () {
    // Show clear button once input field has a value
    $('.form-group').on('focus blur keyup','.form-input', function(e) {
        e.preventDefault();
        if($(this).val().length) {
          $(this).addClass('has-value');
        } else {
          $(this).removeClass('has-value');
        }
    });

    $('.js-clear-input').on('click', function(e) {
        e.preventDefault();
        // Clear input field
        $('[data-postal=api-search-result]').val('');
        $(this).siblings('.form-input.has-value').removeClass('has-value').val('').trigger('focus');

        // If page is Try in store and has results selector
        if ($(TRY_IN_STORE_CONTAINER).length && $(TRY_IN_STORE_RESULTS_SELECTOR).length) {
            $(TRY_IN_STORE_RESULTS_SELECTOR).empty();
            $('.appointment-stores__map-placeholder').removeClass('is-hidden');

        // If page is Appointment and has results selector
        } else if ($(APPOINTMENT_CONTAINER).length && $(APPOINTMENT_RESULTS_CONTAINER_SELECTOR).length) {
            $(APPOINTMENT_RESULTS_CONTAINER_SELECTOR).empty();
            $('.appointment-stores__map-placeholder').removeClass('is-hidden');

            // If page is store locator
        } else {
            $(STORE_RESULTS_CONTAINER_SELECTOR).empty();
        }

        return false;
    });
};
