'use strict';

var subscribeBtnPopUp = $('[data-js="subscribe-popup"]');
var subscribeBtn = $('[data-js="subscribe-btn"]');
var subscribeBtnCancel = $('[data-js="cancel-btn"]');
var subscribeEmail = $('[data-js="subscribe-email"]');
var subscribePopup = $('[data-js="subscribe-confirm"]');
var subscribeCheckbox = $('[data-js="subscribe-checkbox"]');
var subscribeCheckboxFooter = $('[data-js="subscribe-checkbox-footer"]');
var $subscribeForm = $('[data-js="newsletter-footer-form"]');
var createErrorNotification = require('sfra/components/errorNotification');
const scrollDisable = {
    topScroll: 0,
    isScrollDisabled: false,

    disableScroll: function () {
        if (!this.isScrollDisabled) {
            this.topScroll = $(window).scrollTop();
            $('body').addClass('scroll-disabled');
            this.isScrollDisabled = true;
        }
    },

    enableScroll: function () {
        $('body').removeClass('scroll-disabled');
        $(window).scrollTop(this.topScroll);
        this.isScrollDisabled = false;
    }
};

subscribeEmail.keyup(function (e) {
    e.preventDefault();
    if (e.keyCode === 13) {
        subscribeBtnPopUp.click();
        return false;
    }
});

$subscribeForm.submit(function (e) {
    e.preventDefault();
});

subscribeBtn.on('click', function (e) {
    e.preventDefault();
    var $errorContainer = $('[data-js="newsletter-container"]').find('[data-js="newsletter-error"]');
    var $successContainer = $('[data-js="newsletter-subscribe-success-container"]');
    var $newsletterContainer = $('[data-js="newsletter-subscribe-container"]');
    $errorContainer.empty();
    const $form = $('[data-js="newsletter-form"][data-initiated-newsletter-confirm-popup="1"]');

    $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function (response) {
            if (response.gtmData) {
                window.dataLayer.push(response.gtmData);
            }

            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }

            if (response && response.error) {
                createErrorNotification($errorContainer, response.errorMessage);
            }

            if (response && response.success && response.successMessage) {
                createSuccessNotification($newsletterContainer, $successContainer, response.successMessage);
            }
        },
        error: function (err) {
            if (err.responseJSON.errorMessage) {
                createErrorNotification($errorContainer, err.responseJSON.errorMessage);
            }
        }
    });
});

subscribeBtnPopUp.on('click', function () {
    var $this = $(this);
    $('form[data-js="newsletter-form"]').removeAttr('data-initiated-newsletter-confirm-popup');
    var $form = $(this).parents('form[data-js="newsletter-form"]');
    $form.attr('data-initiated-newsletter-confirm-popup', 1);
    $('#offer-consent-footer').prop('checked', false);
    subscribeCheckboxFooter.next(subscribePopup).attr('disabled', !$this.is(':checked'));
    var $errorContainer = $('[data-js="newsletter-container"]').find('[data-js="newsletter-error"]');
    $errorContainer.empty();
    var email;
    if ($this.attr('id') === 'newsletterBannerPopupBtn') {
        subscribeBtn.attr('data-ispdnewslettercomponent', 1);
        email = $form.find(subscribeEmail).val();
    } else {
        subscribeBtn.attr('data-isfooternewsletterform', 1);
        email = $form.find(subscribeEmail).val();
    }
    // eslint-disable-next-line no-useless-escape
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (email === '') {
        $this.parent().addClass('error');
        $this.parent().removeClass('sucess');
    } else if (!emailReg.test(email)) {
        $this.parent().addClass('error');
        $this.parent().removeClass('sucess');
    } else if (emailReg.test(email)) {
        subscribePopup.addClass('open');
        $this.parent().removeClass('error');
        scrollDisable.disableScroll();
    }
});

subscribeCheckbox.find('.custom-control-input').on('click', function () {
    subscribeCheckbox.next(subscribePopup).attr('disabled', !$(this).is(':checked'));
});

subscribeCheckboxFooter.find('.custom-control-input').on('click', function () {
    subscribeCheckboxFooter.next(subscribePopup).attr('disabled', !$(this).is(':checked'));
});

subscribeBtnCancel.on('click', function () {
    subscribePopup.removeClass('open');
    scrollDisable.enableScroll();
});

function createSuccessNotification(formContainer, successContainer, successMessage) {
    if (successMessage && successContainer && successContainer.length) {
        var messageContainer = successContainer.find('[data-js="success-msg"]');
        if (messageContainer.length) {
            messageContainer.empty().append(successMessage);
            formContainer.addClass('is-hidden');
            successContainer.removeClass('is-hidden');
            subscribePopup.removeClass('open');
            scrollDisable.enableScroll();
        }
    }
}
