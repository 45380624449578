'use strict';

var phoneNoMinLength = $('[data-js="phone-input"]').attr('minlength');
var phoneNoMaxLength = $('[data-js="phone-input"]').attr('maxlength');
$('[data-js="phone-input"]').attr('pattern', `.{${phoneNoMinLength},${phoneNoMaxLength}}`);

var patterns = {
    // eslint-disable-next-line
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    phone: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/,
    postalCode: /^([1-9][0-9]{3})\s??(?!SA|SD|SS|sa|sd|ss)([a-zA-Z]{2})$/
};

var DOBInputUtils = require('../../components/DOBInputUtils');

function setElementToValid(element) {
    element.parent().removeClass('invalid').addClass('valid');
}

function setElementToInvalid(element) {
    element.parent().removeClass('valid').addClass('invalid');
}

// Input validation for email
$('[data-validation-js="email-input"]').on('focusout', function () {
    if (patterns.email.test($(this).val())) {
        setElementToValid($(this));
    } else {
        setElementToInvalid($(this));
    }
});

// Input validation for phone
$('[data-validation-js="phone-input"]').on('focusout', function () {
    if (patterns.phone.test($(this).val())) {
        setElementToValid($(this));
    } else {
        setElementToInvalid($(this));
    }
});

// Input validation for postalCode
$('[data-validation-js="postal-code-input"]').on('focusout', function () {
    if (patterns.postalCode.test($(this).val())) {
        setElementToValid($(this));
    } else {
        setElementToInvalid($(this));
    }
});

// Validates against the pattern attribute
$('[data-validation-js="pattern"]').on('focusout', function () {
    const $input = $(this);
    const patternRegExp = new RegExp($input.attr('pattern'));

    if (patternRegExp.test($input.val())) {
        setElementToValid($input);
    } else {
        setElementToInvalid($input);
    }
});

DOBInputUtils.validate();
