'use strict';

var windowWidth = $(window).width();
var navList = $('[data-js="nav-list"]');
var navItem = $('[data-js="nav-item"]');
var navLink = $('[data-js="nav-link"]');
var backBtn = $('[data-js="back-btn"]');
var navWrap = $('[data-js="nav-wrap"]');

// change order if it's more items then 5 on small desktop

if (windowWidth > 1025 && windowWidth < 1249) {
    if (navList.children().length >= 6) {
        $('header').addClass('change-order');
    }
} else {
    $('header').removeClass('change-order');
}

// show on hover for desktop

if (windowWidth >= 1025) {
    navItem.on('mouseenter', function () {
        navItem.not($(this)).removeClass('is-active');
        $(this).addClass('is-active');
    });
    navItem.on('mouseleave', function () {
        $(this).removeClass('is-active');
    });
}
// navigation on click for mobile

if (windowWidth <= 1024) {
    navLink.not(":only-child").on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        navLink.not($(this)).parent().removeClass('is-active');
        $(this).parent().addClass('is-active');
    });
    backBtn.on('click', function () {
        navItem.removeClass('is-active');
    });
}
