'use strict';

/* globals $ */

var trySlider = require('./tryInStoreSlider');
var tryInStoreComponent = $('.try-in-store');
var tryInStoreWrapper = $('.try-in-store-wrapper');

/**
 * @function
 * @description Add a product via ajax on PDP in the 'try in store' component
 */
$('body').on('click', '[data-js="is-store-modal"]', function (e) {
    e.preventDefault();

    // Will change styling for both elements, without location.reload(); ($this) will only change clicked element
    const $tryInStoreBtn = $('[data-js="is-store-modal"]');

    if ($tryInStoreBtn.hasClass('is-disabled')) {
        // Try in store is not available
        $('[data-js="try-in-store-not-available-msg"]').removeClass('is-hidden');
        return;
    }

    $.ajax({
        url: $tryInStoreBtn.attr('href'),
        type: 'POST',
        dataType: 'json',
        data: {
            pid: $tryInStoreBtn.data('pid')
        },
        success: function (data) {
            if (data.success) {
                $tryInStoreBtn.addClass('is-selected');
                $.get(tryInStoreComponent.data('show'), function (data) {
                    updateHTML(data);
                });
            }
        },
        error: function (err) {

        }
    });
});

/**
 * @function
 * @description Add a product via ajax on PLP in the 'try in store' component
 */
$('body').on('click', '[data-js="try-in-store-btn"]', function (e) {
    e.preventDefault();
    var $this = $(this);
    var pid = $this.data('pid');
    var isWishlistPage = $(this).closest('.wishlist-item').length;
    $.ajax({
        url: $this.attr('href'),
        type: 'POST',
        dataType: 'json',
        data: {
            pid: pid
        },
        success: function (data) {
            if (data.success) {
                $this.addClass('is-selected');
                $.get(tryInStoreComponent.data('show'), function (data) {
                    $this.addClass('is-selected');
                    updateHTML(data);
                    if ($('[data-js=try-in-store-product] >  [data-js=product-tile]').length === parseInt(tryInStoreComponent.data('maxitems'))) {
                        $('[data-js="is-store-modal"]').addClass('btn-disabled');
                        $('[data-js="try-in-store-btn"]').addClass('btn-disabled');
                    }
                });
                if (isWishlistPage) {
                    var dataObj = {
                        event: 'wishlistToTiS',
                        eventCategory: 'User interaction',
                        currencyCode: 'EUR',
                        product: pid.toString()
                    };
                    window.dataLayer.push(dataObj);
                }
            } else {
                $this.addClass('btn-disabled');
            }
        },
        error: function (err) {

        }
    });
});

/**
 * @function
 * @description Open the try in store component
 */
$('body').on('click', '[data-js="open-section"]', function () {
    var $this = $(this);
    $this.parent().toggleClass('active');
    $this.next().slideToggle();
});

/**
 * @function
 * @description Remove the product in the try in store component
 */
$('body').on('click', '[data-js="is-store-modal-remove"]', function (e) {
    e.preventDefault();
    var $this = $(this);

    $.ajax({
        url: tryInStoreComponent.data('remove'),
        type: 'POST',
        dataType: 'json',
        data: {
            pid: $this.data('pid')
        },
        success: function (data) {
            if (data.success) {
                var isCurrentPageTryInStore = tryInStoreComponent.data('flowpages').indexOf(window.location.pathname) > -1;
                if (data.itemsCount === 0 && isCurrentPageTryInStore) {
                    $.spinner().start();
                    location.reload();
                }
                $.get(tryInStoreComponent.data('show'), function (data) {
                    updateHTML(data);
                });
                // the data added here is a number, but the one in 'is-store-modal' is a string, so we convert it here
                var $storeModal = $('[data-js="is-store-modal"]');
                if (String($this.data('pid')) === String($storeModal.data('pid'))) {
                    $storeModal.removeClass('is-selected');
                } else {
                    $storeModal.removeClass('btn-disabled');
                }
                $('[data-js="try-in-store-btn"]').removeClass('btn-disabled');
                $('[data-js="try-in-store-btn"][data-pid=' + $this.data('pid') + ']').removeClass('is-selected');
            }
        },
        error: function (err) {

        }
    });
});

/**
 * @function
 * @description updates the product in the try in store component
 */
function updateHTML(data) {
    var jqueryDataHeader = $(data).find('[data-js=try-in-store-title]');
    var jqueryData = $(data).find('[data-js="try-in-store-slider"]');

    $('[data-js="try-in-store-slider"]').replaceWith(jqueryData);
    $('[data-js=try-in-store-title]').replaceWith(jqueryDataHeader);

    // Check if is the last product
    if ($('[data-js=try-in-store-product] > [data-js=product-tile]').length === 0) {
        tryInStoreWrapper.hide();
        $('body').removeClass('js-try-in-store-is-active');
    } else {
        tryInStoreWrapper.show();
        $('body').addClass('js-try-in-store-is-active');
    }

    // After any ajax update we initialize the function again
    initTryInStoreSwiper();
}

$(document).ready(function () {
    $('[data-js=try-in-store-product] > [data-js=product-tile]').length === 0 ? tryInStoreWrapper.hide() : tryInStoreWrapper.show();
    const pid = $('[data-js="is-store-modal"]').data('pid');
    if ($('[data-js=product-tile][data-pid=' + pid + ']').length !== 0) {
        $('[data-js="is-store-modal"]').addClass('is-selected');
    }

    $('[data-js="try-in-store-btn"]').each(function () {
        var thisPid = $(this).data('pid');
        if ($('[data-js=try-in-store-product] > [data-js=product-tile][data-pid=' + thisPid + ']').length !== 0) {
            $(this).addClass('is-selected');
        }
    });

    if ($('[data-js=try-in-store-product] >  [data-js=product-tile]').length === parseInt(tryInStoreComponent.data('maxitems'))) {
        $('[data-js="is-store-modal"]').addClass('btn-disabled');
        $('[data-js="try-in-store-btn"]').addClass('btn-disabled');
    }

    $(document).on('tryinstore-swatch-change', function (e, data) {
        $('[data-js="try-in-store-btn"][data-pid=' + data + ']').each(function () {
            if ($('[data-js=try-in-store-product] > [data-js=product-tile][data-pid=' + data + ']').length !== 0) {
                $(this).addClass('is-selected');
            } else {
                $(this).removeClass('is-selected');
            }
        });
    });
});

/**
 * @function
 * @description Try in Store Mobile swiper
 */
function initTryInStoreSwiper() {
    const breakpoint = window.matchMedia('(min-width: 970px)');
    var mySwiper;
    const breakpointChecker = function () {
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            if (mySwiper !== undefined) mySwiper.destroy(true, true);
            return;
        }
        if (breakpoint.matches === false) {
            // fire small viewport version of swiper
            return trySlider.init();
        }
    };

    if ($('[data-js=try-in-store-product] > [data-js=product-tile]').length > 0) {
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
    }
}

/**
 * @function
 * @description Try in Store Mobile Check
 */

const breakpoint = window.matchMedia('(min-width: 970px)');
var mySwiper;
const breakpointChecker = function () {
    if (breakpoint.matches === true) {
        // clean up old instances and inline styles when available
        if (mySwiper !== undefined) mySwiper.destroy(true, true);
        return;
    }
    if (breakpoint.matches === false) {
        // fire small viewport version of swiper
        return trySlider.init();
    }
};

breakpoint.addListener(breakpointChecker);
breakpointChecker();

/**
 * @function
 * @description Export Function to be available
 */
exports.initTryInStoreSwiper = initTryInStoreSwiper;
