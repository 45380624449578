'use strict';

var formValidation = require('../../components/formValidation');
var DOBInputUtils = require('../../components/DOBInputUtils');

var $contactForm = $('form[data-js="contact-form"]');

function _fillConfirmationMessagePlaceholders($contactForm) {
    let selectedSalutationDisplayValue;
    const $inputSalutation = $contactForm.find('input[data-js="salutation"]:checked');
    if ($inputSalutation.length) {
        selectedSalutationDisplayValue = $.trim($inputSalutation.parent().find('label').text());
    } else {
        selectedSalutationDisplayValue = $.trim($contactForm.find('select[data-js="salutation"] option:selected').text());
    }

    const lastName = $contactForm.find('input[data-js="lastName"]').val();

    const $confirmationContentTitle = $contactForm.parent().find('[data-js="confirmation-content-title"]');
    let confirmationContentTitleText = $confirmationContentTitle.text();
    confirmationContentTitleText = confirmationContentTitleText.replace('{0}', selectedSalutationDisplayValue);
    confirmationContentTitleText = confirmationContentTitleText.replace('{1}', lastName);
    $confirmationContentTitle.text(confirmationContentTitleText)
}

module.exports = {
    onLoad: function () {
        DOBInputUtils.repopulateDOBInputs($contactForm);
    },
    onFormSubmit: function () {
        $contactForm.submit(function (e) {
            const $form = $(this);
            e.preventDefault();
            const url = $form.attr('action');
            $form.spinner().start();

            // Collect DOB values from separated inputs into the hidden input
            DOBInputUtils.saveDOBInputs($(this));

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $form.spinner().stop();
                    if (!data.success) {
                        formValidation($form, data);
                        return;
                    }

                    _fillConfirmationMessagePlaceholders($contactForm);

                    const offset = $contactForm.offset();
                    $('html, body').animate({
                        scrollTop: offset.top - 200
                    }, 300);
                    const $confirmationContent = $contactForm.parent().find('[data-js="confirmation-content"]');
                    $contactForm.remove();
                    $confirmationContent.removeClass('is-hidden');
                },
                error: function (err) {
                    $form.spinner().stop();
                }
            });
        });
    },
}
