'use strict';

var windowWidth = $(window).width();

// search flyout tabs

$('body').on('click', '[data-js="search-tab"]', function () {
    const $this = $(this);
    const thisIndex = $this.index();
    const tabContent = $('[data-js="search-tab-content"]');
    $('[data-js="search-tab"]').removeClass('active');
    $this.addClass('active');
    tabContent.hide();
    tabContent.eq(thisIndex).stop().fadeIn();
});

if (windowWidth <= 1024) // for desktop
{
    $('[data-js="tab"]').on('click', function () {
        const $this = $(this);
        const thisIndex = $this.index();
        const tabContent = $('[data-js="tab-content"]');
        $('[data-js="tab"]').removeClass('active');
        $this.addClass('active');
        tabContent.hide();
        tabContent.eq(thisIndex).stop().fadeIn();
    });
}
