'use strict';

module.exports = function () {
    var fitmixInstance;
    var mainFitmix = $('[data-js="main-fitmix-container"]');
    var apiKey = mainFitmix.data('fittingbox-api');
    if (mainFitmix && apiKey) {
        var params = {
            apiKey: apiKey,
            popupIntegration: {
                centeredHorizontal: true,
                centeredVertical: true,
                width: '100%',
                height: '100%'
            }
        };

        fitmixInstance = FitMix.createWidget('fitmix-container', params);
    }

    $(document).on('click', '[data-js="tryon-btn"]', function (e) {
        e.preventDefault();
        var glassesId = $(this).data('glasses-id');
        fitmixInstance.setFrame(glassesId);
    });
};
