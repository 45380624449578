'use strict';

import Swiper, {Navigation, Scrollbar} from 'swiper';

export function initSwiper() {
    Swiper.use([Navigation, Scrollbar]);

    /**
     * @function
     * @description Swiper configuration for Recommendations slots
     */
    const swiperConfig = {
        spaceBetween: 20,
        grabCursor: true,
        loop: false,
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.recommendations .swiper-button-next',
            prevEl: '.recommendations .swiper-button-prev',
        },
        scrollbar: {
            el: '.recommendations .swiper-scrollbar',
            hide: false,
            draggable: true
        },
        breakpoints: {
            320: {
                spaceBetween: 16,
                slidesPerGroup: 2,
            },
            1023: {
                slidesPerGroup: 3,
            },
            1280: {
                slidesPerGroup: 4,
            }
        }
    }

    if ($('.recommendations-slider').length > 0) {
        $('.recommendations-slider').each(function(i) {
            $(this).addClass('slider-' + i);

            new Swiper('.slider-'+ i +' .js-recommendations-slider', swiperConfig);
        });
    }
}
