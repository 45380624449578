'use strict';

const SELECTORS = {
    customOnlineChatButton: '.js-online-chat-button',
    onlineContactFormLink: '.js-online-contact-form-link',
    sfscOnlineChat: {
        wrapper: '.embeddedServiceHelpButton',
        button: '.embeddedServiceHelpButton .helpButton button',
        popup: '.embeddedServiceSidebar'
    }
};

const $customOnlineChatButton = $(SELECTORS.customOnlineChatButton);
const $onlineContactFormLink = $(SELECTORS.onlineContactFormLink);

function switchFromFormToChatIfOnline() {
    const $sfscOnlineChatButton = $(SELECTORS.sfscOnlineChat.button);

    // Hide online contact form button if online chat is enabled
    if ($customOnlineChatButton.length && $sfscOnlineChatButton && $sfscOnlineChatButton.hasClass('helpButtonEnabled')) {
        $customOnlineChatButton.prop('disabled', false).removeClass('is-disabled');

        if ($onlineContactFormLink.length && !$onlineContactFormLink.hasClass('is-disabled')) {
            $onlineContactFormLink.attr('disabled', true).addClass('is-disabled');
        }
    }
}

let buttonSwitchTimeIntervalTTL = 5;
let timeInterval = setInterval(function () {
    if (buttonSwitchTimeIntervalTTL <= 0) {
        clearInterval(timeInterval);
        return;
    }

    switchFromFormToChatIfOnline();

    buttonSwitchTimeIntervalTTL--;
}, 1000);

// Open online chat popup when clicked on custom chat button.
$customOnlineChatButton.click(function (event) {
    const $sfscOnlineChatButton = $(SELECTORS.sfscOnlineChat.button);
    const $sfscOnlineChatPopup = $(SELECTORS.sfscOnlineChat.popup);

    if ($sfscOnlineChatButton.hasClass('helpButtonEnabled') && !$sfscOnlineChatPopup.length) {
        $sfscOnlineChatButton.click();
    }
});
