/* eslint-disable camelcase */

'use strict';

// Input validation for email

$('[data-js="email-field"]').on('focusout', function () {
    var input = $(this);
    // eslint-disable-next-line no-useless-escape
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var is_email = re.test(input.val());
    if (is_email) {
        input.parent().removeClass('invalid').addClass('valid');
    } else {
        input.parent().removeClass('valid').addClass('invalid');
    }
});

// Input validation for phone

$('[data-js="phone-input"]').on('focusout', function () {
    var input = $(this);
    var phoneNoMinLength = input.attr('minlength');
    var re = new RegExp(`[0-9]{${phoneNoMinLength},}$`);
    var is_phone = re.test(input.val());
    if (is_phone) {
        input.parent().removeClass('invalid').addClass('valid');
    } else {
        input.parent().removeClass('valid').addClass('invalid');
    }
});

// Input validation for appointment phone number
$('[data-js="phone-input-appointment"]').on('focusout', function () {
    var $input = $(this);

    var $countrySelect = $input.parent().find('[data-js="phone-code-select"]');
    var phonePattern = $countrySelect.find(':selected').data('phone-pattern');
    var re = new RegExp(phonePattern);

    var is_phone = re.test($input.val());
    if (is_phone) {
        $input.parent().removeClass('invalid').addClass('valid');
    } else {
        $input.parent().removeClass('valid').addClass('invalid');
    }
});

// Handle country phone code select dropdown changes
$('[data-js="phone-code-select"]').on('change', function () {
    var $selected = $(this).find(':selected');
    var $input = $('[data-js="phone-input-appointment"]');
    // clear if previous error text is shown
    $input.parent().removeClass('invalid').addClass('valid');

    $('.phone-form-error').text($selected.data('range-error'));
    $input.attr('pattern', $selected.data('phone-pattern'));
    $input.attr('placeholder', $selected.data('placeholder'));
});

// Only add numbers and restricts characters
$('[data-js="phone-input"]').on('input', function () {
    this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
});

// Input validation for password

$('[data-js="password-field"]').on('focusout', function () {
    var input = $(this);
    var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*+_\-=?<>;:.'{}()",[|\]\\/])[A-Za-z\d~!@#$%^&*+_\-=?<>;:.'{}()",[|\]\\/]{8,}$/;
    var is_pass = pattern.test(input.val());
    if (is_pass) {
        input.parent().removeClass('invalid').addClass('valid');
    } else {
        input.parent().removeClass('valid').addClass('invalid');
    }
});
// Input validation for password confirm

$('[data-js="password-match"]').on('focusout', function () {
    var input = $(this);
    var match_pass = input.val();
    var first__pass = $('[data-js="password-field"]').val();
    if (match_pass !== first__pass) {
        input.parent().removeClass('valid').addClass('invalid');
    } else {
        input.parent().removeClass('invalid').addClass('valid');
    }
});
