'use strict';

/**
 * Set a cookie
 * @param {string} cookieName - name of the cookie
 * @param {string} cookieValue - value of the ame of the cookie
 * @param {string} exDays - expiration days of the cookie
 */
function setCookie(cookieName, cookieValue, exDays) {
    var date = new Date();
    var daysCounter = exDays * 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + daysCounter);
    var expires = 'expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = {
    setCookie: setCookie,
    getCookie: getCookie
};
