'use strict';

var modalClose = $('[data-js="modal-close"]');
var modalOpen = $('[data-js="modal-open"]');
var modal = $('[data-js="modal"]');

/**
 * @function
 * @description Remove scroll when modal is open
 */
const scrollDisable = {
    topScroll: 0,
    isScrollDisabled: false,

    disableScroll: function () {
        if (!this.isScrollDisabled) {
            this.topScroll = $(window).scrollTop();
            $('body').addClass('scroll-disabled');
            this.isScrollDisabled = true;
        }
    },

    enableScroll: function () {
        $('body').removeClass('scroll-disabled');
        $(window).scrollTop(this.topScroll);
        this.isScrollDisabled = false;
    }
};

/**
 * @function
 * @description Open modal if you click in the link / button
 */

modalOpen.on('click', function (e) {
    e.preventDefault();
    modalFunctions.openModal();
});

/**
 * @function
 * @description Close Modal if you click in the cross
 */
$('body').on('click', '[data-js="modal-close"]', function (e) {
    e.preventDefault();
    modal.removeClass('is-open');
    scrollDisable.enableScroll();
});
/** TODO:
 * @function
 * @description close Modal if you click outside the modal
 */

const modalFunctions = {
    openModal: function () {
        modal.addClass('is-open');
        scrollDisable.disableScroll();
    }
};

module.exports.public = modalFunctions;
