'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('[data-js="suggestions-wrapper"]').data('url');
var minChars = 1;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var tryInStore = require('./tryInStore');
/**
 * @function
 * @description Burger Menu Navigation
 */

const scrollDisable = {
    topScroll: 0,
    isScrollDisabled: false,

    disableScroll: function () {
        if (!this.isScrollDisabled) {
            this.topScroll = $(window).scrollTop();
            $('body').addClass('scroll-disabled');
            this.isScrollDisabled = true;
        }
    },

    enableScroll: function () {
        $('body').removeClass('scroll-disabled');
        $(window).scrollTop(this.topScroll);
        this.isScrollDisabled = false;
    }
};
function initBurgerMenu() {
    var mobileMenu = $('[data-js="mobile-menu"]');
    var searchMenu = $('[data-js="search-form"]');
    var header = $('[data-js="page-header"]');

    $('[data-js="menu-toggle"]').on('click', function () {
        var button = $(this);
        $(this).toggleClass('is-active');

        if (button.attr('aria-expanded') === 'true') {
            $(this).attr('aria-expanded', 'false');
            scrollDisable.enableScroll();
        } else {
            $(this).attr('aria-expanded', 'true');
            scrollDisable.disableScroll();
        }

        mobileMenu.toggleClass('is-active');
        searchMenu.toggleClass('is-active');
        header.toggleClass('is-active');
    });
}

/**
 * @function
 * @description Main Header Search
 */
function initMainHeadSearch() {
    var searchIcon = $('[data-js="search-icon-desktop"]');
    var searchForm = $('[data-js="search-form"]');

    searchIcon.on('click', function () {
        $(this).toggleClass('is-active');
        searchForm.toggleClass('is-active');
        $('[data-js="site-search-input"]').focus();
    });

    $('[data-js="close-search"]').on('click', function (e) {
        e.preventDefault();
        searchForm.toggleClass('is-active');
    });

    $('[data-js="site-search-input"]').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    // handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    // handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: function (response) {
                var $suggestionsWrapper = $('[data-js="suggestions-wrapper"]').empty();
                if (typeof (response) !== 'object') {
                    $suggestionsWrapper.append(response).show();
                    // try in store select
                    $('[data-js="try-in-store-btn"]').each(function () {
                        var pid = $(this).data('pid');
                        if ($('[data-js=try-in-store-product] > [data-js=product-tile][data-pid=' + pid + ']').length !== 0) {
                            $(this).addClass('is-selected');
                        }
                    });
                    if ($('[data-js=try-in-store-product] >  [data-js=product-tile]').length === parseInt($('.try-in-store').data('maxitems'))) {
                        $('[data-js="is-store-modal"]').addClass('btn-disabled');
                        $('[data-js="try-in-store-btn"]').addClass('btn-disabled');
                    }
                    tryInStore.initTryInStoreSwiper();
                } else {
                    $suggestionsWrapper.hide();
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        $('[data-js="suggestions-wrapper"]').empty();
    }
}

/**
 * @function
 * @description Export Function to be available
 */
exports.init = function () {
    initBurgerMenu();
    initMainHeadSearch();
};
