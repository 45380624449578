'use strict';

var createErrorNotification = require('sfra/components/errorNotification');

module.exports = function () {
    // Handles submit of forms for applying Vouchers on Cart page
    $('[data-js="cart-check-voucher-form"]').submit(function (e) {
        e.preventDefault();

        var $form = $(e.target);
        var $formControls = $form.find('[data-js="form-control"]');

        // reset error messages from previous executions
        $formControls.removeClass('is-invalid');
        $('[data-js="input-missing-error"]').addClass('is-hidden');
        $form.find('[data-js="voucher-error-message"]').empty();

        // validate inputs for missing data and return if all are not filled
        var inputsFilled = true;
        $formControls.each(function (i, input) {
            if (!$(input).val()) {
                $(input).addClass('is-invalid');
                $(input).attr('aria-describedby', 'missingData');
                $(input).siblings('[data-js="input-missing-error"]').removeClass('is-hidden');
                inputsFilled = false;
            }
        });

        if (!inputsFilled) {
            return false;
        }

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $form.find('[data-js="voucher-error-message"]').html(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('[data-js="applied-vouchers"]').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('.acc-btn').trigger('click');
                    $('body').trigger('promotion:success', data);
                }
                $formControls.val('');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification('[data-js="voucher-error-message"]', err.responseJSON.errorMessage);
                }
            }
        });
        return false;
    });

    $('body').on('click', '[data-js="remove-voucher"]', function (e) {
        e.preventDefault();

        var $this = $(this);
        $this.parent().find('.error-wrap').remove();

        var data = {
            voucherId: $this.data('voucherid')
        };
        var url = $this.data('href');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: data,
            success: function (response) {
                if (response.error) {
                    $this.parent().append('<div class="error-wrap">' + response.errorMessage + '</div>');
                } else {
                    $('[data-js="applied-vouchers"]').empty().append(response.totals.discountsHtml);
                    updateCartTotals(response);
                    updateApproachingDiscounts(response.approachingDiscounts);
                    validateBasket(response);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '[data-js="remove-coupon"]', function (e) {
        e.preventDefault();

        var $this = $(this);
        var url = $this.data('action');
        var uuid = $this.data('uuid');
        var couponCode = $this.data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (response) {
                if (response.error) {
                    $this.parent().append('<div class="error-wrap">' + response.errorMessage + '</div>');
                } else {
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(response);
                    updateApproachingDiscounts(response.approachingDiscounts);
                    validateBasket(response);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('[name="shippingMethods"]').change(function () {
        var url = $(this).closest('[data-js="cart-shipping-method"]').data('url');
        var urlParams = {
            methodID: $(this).closest('[data-js="cart-shipping-method"]').data('shipping-id')
        };

        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('[data-js="applied-vouchers"]').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    $('body').on('click', '[data-js="js-increment-quantity"]', function () {
        var counter = $(this).siblings('[data-js="js-quantity-counter"]');
        var quantity = counter.val();
        quantity = parseInt(quantity, 10) + 1;

        updateItemQuantity(quantity, counter.parent());
    });

    $('body').on('click', '[data-js="js-decrement-quantity"]', function () {
        var counter = $(this).siblings('[data-js="js-quantity-counter"]');
        var quantity = counter.val();
        quantity = parseInt(quantity, 10) - 1;

        if (quantity === 0) {
            showRemoveItemModal(counter.parent());
        } else {
            updateItemQuantity(quantity, counter.parent());
        }
    });

    $('body').on('click', '[data-js="js-remove-all-quantity"]', function () {
        showRemoveItemModal($(this));
    });

    $('body').on('click', '[data-js="remove-li-close"]', function () {
        $('.quantity-modal').removeClass('show');
    });

    $('body').on('click', '[data-js="remove-li-remove"]', function () {
        $('.quantity-modal').removeClass('show');
        const {
            masterPid,
            masterUuid,
            gtmProductData,
            action
        } = $(this).data();

        const url = appendToUrl(action, { pid: masterPid, uuid: masterUuid });

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart-page').empty().append('<div class="is-container has-padding cart-empty"> '
                        + '<h3 class="h3">' + data.basket.resources.emptyCartMsg + '</h3> '
                        + '<a class="continue-shopping-link" href="' + data.homeUrl + '" title="' + data.continueShopingText + '"> ' + data.continueShopingText + ' </a>'
                        + '</div> ');
                    $('[data-js="cart-count"]').empty().append(data.basket.resources.numberOfItems);
                    $('[data-js="minicart-quantity"]').empty().append(data.basket.numItems);
                    $('[data-js="minicart-link"]').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + masterUuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('[data-js="applied-vouchers"]').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', data);
                $(document).trigger('gtm:productRemoved', gtmProductData);
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    const $couponCodeForm = $('[data-js="promo-code-form"]');
    const $couponCodeFormControl = $couponCodeForm.find('.form-control');
    const $couponCodeMissingError = $('[data-js="coupon-missing-error"]');
    const $couponCodeErrorMessage = $('[data-js="coupon-error-message"]');

    $couponCodeForm.submit(function (e) {
        e.preventDefault();
        $.spinner().start();

        $couponCodeMissingError.hide();
        $couponCodeErrorMessage.empty();
        if (!$('.coupon-code-field').val()) {
            $couponCodeFormControl
                .addClass('is-invalid')
                .attr('aria-describedby', 'missingCouponCode');
            $couponCodeMissingError.show();
            $.spinner().stop();
            return false;
        }

        $couponCodeFormControl.removeClass('is-invalid');
        $couponCodeErrorMessage.empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $couponCodeForm.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $couponCodeForm.serialize(),
            success: function (data) {
                if (data.error) {
                    $couponCodeFormControl
                        .addClass('is-invalid')
                        .attr('aria-describedby', 'invalidCouponCode');
                    $couponCodeErrorMessage.empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });
};

function updateItemQuantity(quantity, $counterWrap) {
    var productID = $counterWrap.data('pid');
    var url = $counterWrap.data('action');
    var uuid = $counterWrap.data('uuid');

    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };
    url = appendToUrl(url, urlParams);

    $('body').trigger('cart:beforeUpdate');

    var $errorWrap = $counterWrap.closest('[data-js="cart-product-line-item"]').find('[data-js="cart-lineitem-availibility"]');
    $errorWrap.empty();

    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            if (data.errorMessage) {
                $errorWrap.html(data.errorMessage);
                return;
            }
            $('[data-js="applied-vouchers"]').empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            validateBasket(data);

            $counterWrap.find('[data-js="js-quantity-counter"]').val(quantity);

            $('body').trigger('cart:update', data);

            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                window.location.reload();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
            }
        }
    });
}

function showRemoveItemModal($counterWrap) {
    const $targetModal = $(`[data-js-uuid="quantity-modal-${$counterWrap.data('uuid')}"]`);

    const hasLtpDiscount = $counterWrap.data('has-ltp-discount');

    $targetModal.find('[data-js="ltpDiscountMessage"]').toggleClass('is-hidden', !hasLtpDiscount);
    $targetModal.addClass('show');
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('[data-js="cart-count"]').empty().append(data.resources.numberOfItems);
    $('[data-js="shipping-cost"]').empty().append(data.totals.totalShippingCost);
    $('[data-js="grand-total"]').empty().append(data.totals.grandTotal);
    $('[data-js="sub-total"]').empty().append(data.totals.subTotal);
    $('[data-js="minicart-quantity"]').empty().append(data.numItems);
    $('[data-js="minicart-link"]').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('[data-js="order-discount"]').removeClass('is-hidden');
        $('[data-js="order-discount-total"]').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('[data-js="order-discount"]').addClass('is-hidden');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('[data-js="shipping-discount"]').removeClass('is-hidden');
        $('[data-js="shipping-discount-total"]').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('[data-js="shipping-discount"]').addClass('is-hidden');
    }

    if (data.totals.totalTax !== '-') {
        $('[data-js="cart-tax"]').removeClass('is-hidden');
        $('[data-js="tax-total"]').empty().append(data.totals.totalTax);
    } else {
        $('[data-js="cart-tax"]').addClass('is-hidden');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('[data-js="applied-vouchers"]').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    data.shipments[0].shippingMethods.forEach(function (method) {
        var methodContainerSelector = '[data-js="shipping-method-option"]#' + method.ID;
        $(methodContainerSelector).parent().find('[data-js="method-cost"]').empty()
            .append(method.shippingCost);
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
            window.scroll({ top: 0, behavior: 'smooth' });
        } else {
            $('.cart').empty().append('<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>' + data.resources.emptyCartMsg + '</h1> '
                + '</div> '
                + '</div>');
            $('[data-js="cart-count"]').empty().append(data.resources.numberOfItems);
            $('[data-js="minicart-quantity"]').empty().append(data.numItems);
            $('[data-js="minicart-link"]').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}
