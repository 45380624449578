/**
 * @file This file provides utility methods for Date Of Birth component at components/form/DOBInput.isml
 *
 * Import this file inside the form handler script.
 *
 */

/**
 * When called -on form submit mainly-, it will read the separated date inputs, combine them into DD-MM-YYYY
 * format and write to hidden input.
 * @param $container JQuery DOM element instance that contains the Date Of Birth component in it -mainly the form-.
 */
const saveDOBInputs = function ($container) {
    const dayOfBirth = $container.find('input#dayDOBInput').val();
    const monthOfBirth = $container.find('input#monthDOBInput').val();
    const yearOfBirth = $container.find('input#yearDOBInput').val();
    const combinedDOB = [dayOfBirth, monthOfBirth, yearOfBirth].join('-');

    $container.find('input#combinedDOBInput').val(combinedDOB);
};

/**
 * When called -on page load mainly-, it will read the date from hidden input and split and write to separated date
 * inputs.
 * @param $container DOM element that contains the Date Of Birth component.
 */
const repopulateDOBInputs = function ($container) {
    let savedDOB = $container.find('input#combinedDOBInput').val();

    if (!!savedDOB === false || savedDOB === "undefined") {
        return;
    }

    savedDOB = savedDOB.split('-');

    $container.find('input#dayDOBInput').val(savedDOB[0]);
    $container.find('input#monthDOBInput').val(savedDOB[1]);
    $container.find('input#yearDOBInput').val(savedDOB[2]);
};

/**
 * Determines if the given year is a leap year or not
 * @param {int} year
 * @returns {boolean}
 */
function isLeapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

/**
 * This method must be executed on the pages the component is used, or just in the formValidation.js
 */
const validate = function () {
    var dayOfBirth = $('[data-js="day-born"]');
    var monthOfBirth = $('[data-js="month-born"]');
    var yearOfBirth = $('[data-js="year-born"]');
    var customCalendar = $('[data-js="custom-date"]');
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    // Date of birth
    $('[data-js="day-born"], [data-js="month-born"], [data-js="year-born"]').on('input', function () {
        this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');

        if (dayOfBirth.val() > 31) {
            dayOfBirth.val('31');
        }
        if (dayOfBirth.val() > 30 && ( monthOfBirth.val() == 4 || monthOfBirth.val() == 6 || monthOfBirth.val() == 9 || monthOfBirth.val() == 11)) {
            dayOfBirth.val('30');
        }
        if (monthOfBirth.val() > 12) {
            monthOfBirth.val('12');
        }
        if (yearOfBirth.val() > currentYear - 1) {
            yearOfBirth.val(currentYear - 1);
        }
    });

    // Adding "0" if only one integer is entered
    $('[data-js="day-born"], [data-js="month-born"]').on('focusout', function () {
        if(this.value === 0 || this.value === '0' || this.value === '00'){
           this.value = '01';
        }
        if (this.value.length <= 1 && this.value > 0) {
            this.value = this.value.replace(/^/, '0');
        }
    })

    // Checking leaping year and customer age
    $('[data-js="day-born"], [data-js="month-born"], [data-js="year-born"]').on('focusout', function () {
        let isValid = true;
        if ((yearOfBirth.val() > currentYear - 1 || yearOfBirth.val() < 1900)) {
            yearOfBirth.val('');
            isValid = false;
        }
        if ((isLeapYear(yearOfBirth.val()) && monthOfBirth.val() == 2) && (dayOfBirth.val() < 30)) {
            dayOfBirth.val();
            isValid = false;
        } else if ((!isLeapYear(yearOfBirth) && monthOfBirth.val() == 2) && (dayOfBirth.val() > 28)) {
            dayOfBirth.val('28');
        }

        var birthday = [dayOfBirth.val(), monthOfBirth.val(), yearOfBirth.val()];

        // Checks if customer is older then a one year
        var birthCalc = new Date(birthday[2], birthday[1] - 1, birthday[0]);
        var timeDifference = Math.abs(birthCalc.getTime() - currentDate.getTime());
        var daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (daysDifference < 365) {
            dayOfBirth.val('');
            monthOfBirth.val('');
            yearOfBirth.val('');
            isValid = false;
        }

        // Set value of hidden input
        if (isValid) {
            customCalendar.attr('value', birthday.join('-'));
        } else {
            customCalendar.attr('value', '');
        }
    })
}

module.exports = {
    saveDOBInputs: saveDOBInputs,
    repopulateDOBInputs: repopulateDOBInputs,
    validate: validate
};
