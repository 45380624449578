
'use strict';

import Swiper, {Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

/**
 * @function
 * @description Slider used in the Content Slider Component for Page designer
 */
var swiperSlider = new Swiper('.js-slider', {
  spaceBetween: 24,
  loop: false,
  slidesPerView: 'auto',
  loop: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {

    320: {
      spaceBetween: 16,
      slidesPerGroup: 2,
    },
    1023: {
      slidesPerGroup: 3,
    },
    1280: {
      slidesPerGroup: 4,
    }
  }
});

/**
 * @function
 * @description Slider used in the Hero Slider Component for Page designer
 */
var swiperSlider = new Swiper('.js-hero-slider', {
  spaceBetween: 16,
  loop: false,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {
    768: {
      spaceBetween: 25,

    },
    1320: {
      slidesPerView: '3',
    }
  }
});
/**
 * @function
 * @description Slider used in the Step indicator Component for Page designer
 */
var swiperSlider = new Swiper('.js-stepindicator-slider', {
  spaceBetween: 16,
  loop: false,
  slidesPerView: 'auto',
  direction: 'vertical',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true
  },
  breakpoints: {
    768: {
      spaceBetween: 25,

    },
    1320: {
      slidesPerView: '3',
    }
  }
});

