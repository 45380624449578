'use strict';

var $ = require('jquery');
// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = $;

var createErrorNotification = require('sfra/components/errorNotification');

// Handles checkbox update, showing text only when settings have changed from
// initial settings
$("input[data-js='newsletter-checkbox']").on('click', function () {
    var checkboxes = $('[data-js="newsletter-form"]').find('[data-js="newsletter-checkbox"]');
    var text = $("[data-js='newsletter-msg']");
    var showText = false;
    checkboxes.each(function (i, checkbox) {
        if ($(checkbox).is(':checked') !== $(checkbox).data().initial) {
            showText = true;
        }
    });
    if (showText) {
        $(text).show();
    } else {
        $(text).hide();
    }
});

$('[data-js="newsletter-submit"]').on('click', function (e) {
    e.preventDefault();
    var $errorContainer = $('[data-js="newsletter-container"]').find('[data-js="newsletter-error"]');
    $errorContainer.empty();
    var $form = $('[data-js="newsletter-form"]');

    $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function (response) {
            if (response.gtmData) {
                window.dataLayer.push(response.gtmData);
            }

            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }

            if (response && response.error) {
                createErrorNotification($errorContainer, response.errorMessage);
            }
        },
        error: function (err) {
            if (err.responseJSON.errorMessage) {
                createErrorNotification($errorContainer, err.responseJSON.errorMessage);
            }
        }
    });
});
