import Swiper, {Navigation, Pagination} from 'swiper';

//Try is store slider on mobile

export function init() {
    Swiper.use([Navigation, Pagination]);

    var trySlider = new Swiper('[data-js="try-in-store-slider"]', {
        spaceBetween: 16,
        loop: false,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
            draggable: true
        }
    });
}

