'use strict';

const debounce = require('lodash/debounce'); // We are using the debounce to improve the performance

/**
 * @function
 * @description Sticky Bars ( Header // Sticky Links // Try in store)
 */
function headerBars() {
    // in this script they will be set to the correct top position and get class 'bar-fixed'(position: fixed)
    const mobileBreakpoint = window.matchMedia('(max-width: 767px)').matches;
    const tabletBreakpoint = window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches;
    let sizeOfBar0 = 0; let sizeOfBar1 = 0; let sizeOfBar2 = 0; let
        sizeOfBar3 = 0;

    const bar0 = $('[data-js="header-usp"]');
    const bar1 = $('[data-js="page-header"]');
    const bar2 = $('[data-js="sticky-links"]');
    const bar2b = $('.js-sticky-links');
    const bar3 = $('[data-js="try-in-store"]');

    // Check if is on the Home page
    if ($('.is-homepage-show').length == 1) {
        if ($('.homepage-main [data-js="try-in-store"]').length == 0) {
            $(bar2b).after(bar3);
        }
    }

    // Get all heights if available:
    sizeOfBar1 = bar1.outerHeight();

    // USP Bar is not visible on Mobile, this is the check
    if (!mobileBreakpoint || !tabletBreakpoint) {
        sizeOfBar0 = bar0.outerHeight();
    }

    if (bar2.length == 1) {
        sizeOfBar2 = bar2.outerHeight();
    } else if (bar2b.length == 1) {
        sizeOfBar2 = bar2b.outerHeight();
    }

    if ($(bar3).css('display') !== 'none') {
        sizeOfBar3 = bar3.outerHeight();
    }

    // Create the Spacer to improve the scroll animation
    if ($('.js-spacer').length !== 1) {
        const placeHolder = '<div class="js-spacer"></div>';
        bar1.after(placeHolder);
    }

    // Set Mobile positions
    function setMobileScrollingOffsets() {
        if (bar2.length == 1) {
            bar2.css('top', '0');
            bar3.css('top', sizeOfBar2 + 'px');
        } else if (bar2b.length == 1) {
            bar2b.css('top', '0');
            bar3.css('top', sizeOfBar2 + 'px');
        } else {
            bar3.css('top', '0');
        }
    }

    // Set Desktop positions
    function setDesktopOffsets() {
        bar1.css('top', '0');
        if (bar2.length == 1) {
            bar2.css('top', sizeOfBar1 + 'px');
            bar3.css('top', (sizeOfBar1 + sizeOfBar2) + 'px');
        } else if (bar2b.length == 1) {
            bar2b.css('top', sizeOfBar1 + 'px');
            bar3.css('top', (sizeOfBar1 + sizeOfBar2) + 'px');
        } else {
            bar3.css('top', sizeOfBar1 + 'px');
        }
    }

    // Set no off Sets
    function setNoOffSets() {
        bar1.css('top', '0');
        if (bar2.length == 1) {
            bar2.css('top', '0');
            bar3.css('top', '0');
        } else if (bar2b.length == 1) {
            bar2b.css('top', '0');
            bar3.css('top', '0');
        } else {
            bar3.css('top', '0');
        }
    }
    // When we start scrolldown
    function scrollDown() {
        setDesktopOffsets();
        setSpacerHeight('on');
        $(bar1).addClass('bar-fixed');
        if (bar2.length == 1) {
            $(bar2).addClass('bar-fixed');
        } else if (bar2b.length == 1) {
            $(bar2b).addClass('bar-fixed');
        }
        $(bar3).addClass('bar-fixed');
    }

    // When we start scrollUp
    function scrollUp() {
        setNoOffSets();
        setSpacerHeight('off');
        $(bar1).removeClass('bar-fixed');
        if (bar2.length == 1) {
            $(bar2).removeClass('bar-fixed');
        } else if (bar2b.length == 1) {
            $(bar2b).removeClass('bar-fixed');
        }
        $(bar3).removeClass('bar-fixed');
    }

    // This is to avoid the jump when we scroll
    function setSpacerHeight(onOff) {
        let spacerHeight = '0';

        // We don't use 'off' but for the readibility we left there
        if (onOff == 'on') {
            if (mobileBreakpoint) {
                spacerHeight = sizeOfBar2 + sizeOfBar3;
            } else {
                spacerHeight = sizeOfBar1 + sizeOfBar2 + sizeOfBar3;
            }
        }

        $('.js-spacer').css('height', spacerHeight + 'px');
    }

    // Mobile:
    if (mobileBreakpoint) {
        // remove desktop/tablet properties if it became 'mobile' through screen resize
        $(bar1).removeClass('bar-fixed');
        $(bar2).removeClass('bar-fixed');
        $(bar2b).removeClass('bar-fixed');
        $(bar3).removeClass('bar-fixed');

        // header 1 should not be sticky
        // as soon as we have scrolled the height of header 1, the others should become sticky
        if ($(window).scrollTop() > bar1.outerHeight()) {
            setMobileScrollingOffsets();
            setSpacerHeight('on');
            if (bar2.length == 1) {
                $(bar2).addClass('bar-fixed');
            } else if (bar2b.length == 1) {
                $(bar2b).addClass('bar-fixed');
            }
            $(bar3).addClass('bar-fixed');
        } else {
            // as long as we have scrolled less then the height of header 1, the others should not be sticky
            setNoOffSets();
            setSpacerHeight('off');
            if (bar2.length == 1) {
                $(bar2).removeClass('bar-fixed');
            } else if (bar2b.length == 1) {
                $(bar2b).removeClass('bar-fixed');
            }
            $(bar3).removeClass('bar-fixed');
        }
    } else if (tabletBreakpoint) {
        // Destktop and Tablet: When it goes down
        if ($(window).scrollTop() > 1) {
            scrollDown();
        } else {
            // When is on the Top
            scrollUp();
        }
    } else {
        // Destktop and Tablet: When it goes down
        if ($(window).scrollTop() > sizeOfBar0) {
            scrollDown();
        } else {
            // When is on the Top
            scrollUp();
        }
    }
}

/**
 * @function
 * @description We will execute the main function (headerBars) on load / scroll  and resize to adjust everytime
 */

$(window)
    .on('load scroll', function () {
        headerBars();
    })
    .on('resize', debounce(() => {
        headerBars();
    }, 600)); // This parameter is about the debounce
