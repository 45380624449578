
'use strict';

import Swiper, {Navigation, Pagination} from 'swiper';
Swiper.use([Navigation, Pagination]);

var swiperSlider = new Swiper('.js-swiper', {
   spaceBetween: 10,
   loop: false,
   slidesPerView: 1,
   navigation: {
     nextEl: '.swiper-button-next',
     prevEl: '.swiper-button-prev',
   },
 });


