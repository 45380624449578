'use strict';
import {DateTimePicker} from 'datetimepicker';
/**
 * @function
 * @description Change Calendar date format after press
 */

$(document).ready(function()
{
    $(".js-date-box").DateTimePicker({
        isPopup: false,
        setValueInTextboxOnEveryClick: true,
        minDate: "01-01-1900",
        maxDate: new Date(),
        titleContentDate: $(".js-date-box").data('title'),
        setButtonContent: $(".js-date-box").data('set-btn'),
		clearButtonContent: $(".js-date-box").data('clear-btn')
    });

});
