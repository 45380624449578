'use strict';

/**
 * @function
 * @description Add lazy loading
 */

/** First we get all the non-loaded image elements * */
var lazyImages = [].slice.call(document.querySelectorAll('.product__image-tile.lazy, .product-tile__image.lazy, home-banner__img.lazy'));

/** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element * */
let lazyImageObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove('lazy');
            lazyImageObserver.unobserve(lazyImage);
        }
    });
});

/** Now observe all the non-loaded images using the observer we have setup above * */
lazyImages.forEach(function (lazyImage) {
    lazyImageObserver.observe(lazyImage);
});

let newElementsObserver = new MutationObserver(function () {
    var newLazyImages = [].slice.call(document.querySelectorAll('.product__image-tile.lazy, .product-tile__image.lazy, home-banner__img.lazy'));
    newLazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });
});
$(document).ready(function () {
    const rootElement = document.getElementById('js-main-page');

    if (rootElement) {
        newElementsObserver.observe(rootElement, {
            attributes: false, childList: true, characterData: false, subtree: true
        });
    }
});
