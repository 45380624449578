'use strict';

const selectors = {
    form: 'form#js-online-contact-form',
    formSubmittedMessage: '.js-online-contact-form-submit-message',
    formInvalidMessage: '.js-online-contact-form-validation-error-message',
    formPageLink: '.js-online-contact-form-link'
};

const urlParams = new URLSearchParams(window.location.search);
const contactFormSubmitted = urlParams.get('contactFormSubmitted');

if (contactFormSubmitted) {
    $(selectors.formSubmittedMessage).removeClass('is-hidden');
}

$(selectors.formPageLink).click(function (event) {
    if ($(this).attr('disabled')) {
        event.preventDefault();
    }
});

$(selectors.form).submit(function (event) {
    const invalidFromGroups = $(this).find('.form-group.invalid');

    if (invalidFromGroups.length) {
        event.preventDefault();

        $(selectors.formInvalidMessage).removeClass('is-hidden');
        $(selectors.formInvalidMessage)[0].scrollIntoView({ behavior: 'smooth' });
    }
});
